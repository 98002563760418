import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/mqu/cards.png";
import img2 from "../images/mqu/buttons.png";
import img3 from "../images/mqu/tiles.png";
import img4 from "../images/mqu/alerts.png";
import img5 from "../images/mqu/forms.png";
import img6 from "../images/mqu/footers.png";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Design System"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `website`, `ebooks`, `wordpress`, `woocommerce`]}
    />
    <div className="portfolio-header">
      <h1>MQU Design System</h1>
      <h5>Design System</h5>
      <p>Design System for Macquarie University working closely with a team of UX researchers, UI designers, full-stack developers and product managers at FriendIy Agency</p>
    </div>
    {/* <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://wushka.com.au" target="_blank" rel="noopener noreferrer">View Project</a>
    </div> */}
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Buttons</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Cards</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Tiles</figcaption>
        <img src={img3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Alerts</figcaption>
        <img src={img4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Forms</figcaption>
        <img src={img5} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Footers</figcaption>
        <img src={img6} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
